.appointment-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
}

/* create and edit appointment modal */
.cstm-fast-access-btn{
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    text-decoration: none;
}
/* create and edit appointment modal end*/

/* fullcalendar */
/* header, cell and number styles */
.appointment-container .fc .fc-view-container .fc-head{
    border-color: #ddd;
}

.appointment-container .fc .fc-col-header-cell-cushion{
    text-decoration: none;
    color:  #666666;
} 

.appointment-container .fc .fc-daygrid-day-number{
    text-decoration: none;
    color:  #797979;
    font-size: 14px;
}

/* header, cell and number styles end*/

/* past day style */
.appointment-container .fc .fc-day-past{
    background-color: #ebeff2;
}
/* past day style end*/

/* buttons style */
.appointment-container .fc .fc-button-group .fc-prev-button,
.appointment-container .fc .fc-button-group .fc-next-button{
    border: 1px solid #1b99ff;
    color: #1b99ff;
    width: auto;
    height: auto;
    background: none;
    outline: none!important;
    box-shadow: none !important;
    transition: .3s;
}

.appointment-container .fc .fc-today-button::first-letter{
    text-transform: uppercase;
}

.appointment-container .fc .fc-today-button{
    border: 1px solid #1b99ff;
    color: #ffffff;
    width: auto;
    height: auto;
    background: #1b99ff;
    outline: none!important;
    box-shadow: none !important;
}

.appointment-container .fc .fc-today-button:active{
    background: #1b99ff!important;
    border: 1px solid #1b99ff!important;
}

.appointment-container .fc .fc-today-button:disabled{
    opacity: .5;
}

.appointment-container .fc .fc-dayGridMonth-button,
.appointment-container .fc .fc-timeGridWeek-button,
.appointment-container .fc .fc-timeGridDay-button{
    border: 1px solid #1b99ff;
    color: #1b99ff;
    width: auto;
    height: auto;
    background: none;
    outline: none!important;
    box-shadow: none !important;
    transition: .3s;
}

.appointment-container .fc .fc-dayGridMonth-button.fc-button-active,
.appointment-container .fc .fc-timeGridWeek-button.fc-button-active,
.appointment-container .fc .fc-timeGridDay-button.fc-button-active{
    background: #1b99ff!important;
    border: 1px solid #1b99ff!important;
}

.appointment-container .fc .fc-button-group .fc-prev-button:hover,
.appointment-container .fc .fc-button-group .fc-next-button:hover{
    border: 1px solid #1b99ff;
    color: #ffffff;
    background-color: #1b99ff;
    transition: .3s;
}

/* buttons style end*/

/* fullcalendar event style */

.fc-event-cstm-style{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    padding: 4px 5px;
    margin: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.fc-event-cstm-style > .time{
    width: auto;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: bold;
    padding-right: 5px;
}

.fc-event-cstm-style > .title{
    width: auto;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: normal;
    padding-right: 5px;
}

.fc-event-cstm-style > .status{
    width: auto;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: bold;
}

.appointment-info-tooltip-wrap{
    width: 100%!important;
    height: auto!important;
    position: relative!important;
    font-size: 12px!important;
    font-weight: normal!important;
}

.appointment-info-tooltip{
    width: auto!important;
    height: auto!important;
    position: relative!important;
    display: inline-block!important;
    font-family: 'Roboto', sans-serif!important;
    font-size: 13px!important;
    font-weight: bolder!important;
    padding-right: 10px !important;
}

.fc-event{
    background: transparent;
    border: none;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
    box-shadow: none!important;
}

/* fullcalendar event style end*/
/* fullcalendar end */

#appointment-pre-loading-content{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: rgba(205, 205, 205, 0.6);
}

#appointment-pre-loading-content > .loading{
    width: auto;
    height: auto;
    position: relative;
    color: #fff;
}