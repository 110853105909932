
.image-archive-wrapper{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
}

.image-archive-wrapper > .img-upload-container{
    width: 100%;
    height: 260px;
    position: relative;
    float: left;
    border: 2px dashed #c7cbce;
    border-radius: 15px;
}

.image-archive-wrapper > .img-upload-container > .content-wrap{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    background-color: #ffffff;
    padding: 20px 0;
    border-radius: 15px;
}

.image-archive-wrapper > .img-upload-container > .content-wrap.drag-active{
    background-color: #c6e0f746;
    opacity: 0.5;
}


.image-archive-wrapper > .img-upload-container > .content-wrap > .upload-icon-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: flex;
    justify-content: center;
}

.image-archive-wrapper > .img-upload-container > .content-wrap > .upload-icon-wrap > img{
    width: auto;
    max-width: 70px;
    height: auto;
    position: relative;
    float: left;
}

.image-archive-wrapper > .img-upload-container > .content-wrap > .desc-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    text-align: center;
    color: #80878b;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin: 20px 0;
}

.image-archive-wrapper > .img-upload-container > .content-wrap > .sub-desc-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    text-align: center;
    color: #80878b;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
}

.image-archive-wrapper > .img-upload-container > .content-wrap > .upload-button{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: flex;
    justify-content: center;
}



.image-archive-wrapper > .img-upload-container > .content-wrap > .upload-button > button{
    width: auto;
    height: auto;
    position: relative;
    float: left;
    margin-top: 25px;
    background: none;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    color: #0fc7ff;
    border: 2px solid #0fc7ff;
    border-radius: 5px;
    transition: .3s ease;
}

.image-archive-wrapper > .img-upload-container > .content-wrap > .upload-button > button:hover{
    transition: .3s ease;
    color: #fff;
    background-color: #0fc7ff;;
}

/* selected images preview*/
.selected-images-preview-wrap{
    width: 100%;
    height: 170px;
    position: relative;
    float: left;
    overflow: hidden;
    margin-bottom: 20px;
    border: 1px solid rgb(223, 223, 223);
}

.selected-images-preview-wrap .header{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    font-size: 16px;
    z-index: 1;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    color: #1172b6;
    padding-top: 2px;
    padding-left: 20px;
}

.selected-images-preview-wrap > .content{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    overflow: auto;
    padding: 0 20px;
}

.selected-images-preview-wrap > .content .item-wrap{
    width: 120px;
    height: 120px;
    position: relative;
    float: left;
    border: 2px solid #c7cbce;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.selected-images-preview-wrap > .content .item-wrap .remove-btn{
    position: absolute;
    content: '';
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    color: rgb(193, 193, 193);
}

.selected-images-preview-wrap > .content .item-wrap .remove-btn:hover{
    cursor: pointer;
}

.selected-images-preview-wrap > .content .item-wrap > .img{
    width: auto;
    height: auto;
    max-width: 85px;
    max-height: 85px;
    position: relative;
    float: left;
}


/*  */
#input-file-upload {
    display: none;
}
  
#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


/* patient-images-archive */

.p-i-a-divider-line{
    width: 100%;
    height: 4px;
    background-color: #1172b6;
    position: relative;
    float: left;
    margin: 60px 0 30px 0;
}


.patient-images-archive-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
}

.patient-images-archive-wrap > .header{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    font-size: 20px;
    z-index: 1;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    color: #1172b6;
    line-height: 18px;
    padding: 0 0;
    font-weight: 400;
    padding-left: 10px;
    margin-bottom: 10px;
}

.patient-images-archive-wrap > .images-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
}

.patient-images-archive-wrap > .images-container .i-card-item{
    width: 100%;
    height: 300px;
    position: relative;
    float: left;
    cursor: pointer;
    margin-top: 10px;
}

.patient-images-archive-wrap > .images-container .i-card-item > .i-card{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
}

.patient-images-archive-wrap > .images-container .i-card-item > .i-card > img{
    width: 100%;
    height: auto;
    max-height: 300px;
    position: relative;
    float: left;
}

.p-i-a-cstm-delete-btn{
    cursor: pointer!important;
    color: #d32f2f;
    font-size: 20px;
}
