.expense-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 20px 15px 30px 15px;
}

.total-pay-info-btn{
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    background-color: #4c5667;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 15px;
    border-radius: 3px;
}

.total-remaining-info-btn{
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    background-color: #ff7071;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 15px;
    border-radius: 3px;
}

.total-overpaid-info-btn{
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    background-color: #1abc9c;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 15px;
    border-radius: 3px;
}
