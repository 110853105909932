
.patient-detail-treatment-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
}

.bottom-tooth-container,
.top-tooth-container{
    width: auto;
    height: auto;
    position: relative;
    float: left;
}

.top-tooth-container .tooth,
.bottom-tooth-container .tooth{
    position: relative;
    width: auto;
    height: auto;
    float: left;
}

/* tooth area */
.top-tooth-img{
    padding-top: 10px;
    padding-bottom: 8px;
}

.bottom-tooth-img{
    padding-bottom: 10px;
    padding-top: 8px;
}

.tooth-img:hover{
    cursor: pointer;
}

.tooth-img:hover img{
    transition: 0.3s;
    background-color: rgba(255, 32, 32, 0.253);
    border-radius: 5px;
}

.tooth-img div{
    height: 90px;
    width: 100%;
    position: relative;
    float: left;
    overflow: hidden;
}

