.settings-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
}



.settings-logo-preview-wrap{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.settings-logo-preview-wrap > img{
    width: 100%;
    max-width: 250px;
    height: auto;
    position: relative;
    border-radius: 10px;
    border: 2px solid #37bef0;;
}

