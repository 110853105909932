.report-notebook-analysis-container {
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
}

.report-notebook-analysis-title {
    color: #1172B6;
}

.report-notebook-analysis-button-bgColor {
    background-color: #1172B6 !important;
}