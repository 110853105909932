.d-doctor-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    padding: 10px 5px;
}

.doctor-list-card{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    margin-top: 20px;
}

.doctor-list-card .card-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    margin: 10px 0;
    padding: 10px;
    padding-top: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.doctor-list-card .card-wrap > .card-divide{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: grid;
    grid-template-columns: 100px 1fr;
}

.doctor-list-card .card-wrap > .card-divide > .info-avatar{
    width: 70px;
    height: 70px;
    position: relative;
    float: left;
    margin-left: 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    font-weight: bolder;
} 

.doctor-list-card .card-wrap > .card-divide > .info-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
}


.doctor-list-card .card-wrap > .card-divide > .info-wrap > .header{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    color: #505458;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.04em;
    line-height: 16px;
    margin-bottom: 10px;
}

.doctor-list-card .card-wrap > .card-divide > .info-wrap > .sub-header{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    color: #b9c0c2;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 14px;
    margin-bottom: 10px;
}

.doctor-list-card .card-wrap > .card-divide > .info-wrap > .phone{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    color: #797979;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 13px;
    margin: 10px 0;
}

.doctor-list-card .card-wrap > .card-divide > .info-wrap > .email{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    color: #797979;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 13px;
    margin-bottom: 10px;
}

.doctor-list-card .card-wrap > .card-divide > .info-wrap > .status{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
    color: #797979;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 13px;
    margin: 5px 0 15px 0;
}

.doctor-list-card .card-wrap > .card-divide > .info-wrap > .status.active{
    color: #1abc9c;
}

.doctor-list-card .card-wrap > .card-divide > .info-wrap > .status.passive{
    color: #df4944;
}

.doctor-list-card .card-wrap > .card-divide > .info-wrap span{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    display: flex!important;
    align-items: center!important;
}

.doctor-list-card .card-wrap .action-buttons{
    position: absolute;
    width: auto;
    height: auto;
    top: 10px;
    right: 10px;
}

.doctor-list-card .card-wrap .action-buttons button{
    width: 32px;
    height: 30px;
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    border-radius: 3px;
    font-size: 15px;
    font-weight: bold;
}

.doctor-list-card .card-wrap .action-buttons button.edit{
    background-color: #28a745;
    color: #fff;
    margin-right: 5px;
}

.doctor-list-card .card-wrap .action-buttons button.delete{
    background-color: #dc3545;
    color: #fff;
    margin-right: 5px;
}

.doctor-list-card .card-wrap .action-buttons button.permission{
    background-color: #f0ad4e;
    color: #fff;
}

.doctor-page-loading {
    width: 100%;
    height: 60vh;
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}




 