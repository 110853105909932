.not-found-container{
    width: 100%;
    height: 100vh;
    position: fixed;
    float: left;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.not-found-container > .not-found-wrap{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    background-color: #05a9ea;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.not-found-container > .not-found-wrap > .not-found{
    width: auto;
    height: auto;
    position: relative;
    float: left;
}

.not-found-container > .not-found-wrap > .not-found > img{
    width: 100%;
    max-width: 800px;
    height: auto;
    position: relative;
    float: left;
} 


