.header-nav{
    width: 100%;
    height: auto;
    position: sticky;
    top: -20px;
    float: left;
    /* background: #e6eefd; */
    background: #6aa6d0;
    border-radius: 3px;
    margin-bottom: 20px;
    z-index: 999;
}

.header-nav > .header-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    padding: 8px 20px;
}

/* info menu btn */
.header-nav > .header-wrap > .info-menu-btn{
    width: auto;
    height: auto;
    position: absolute;
    float: left;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    left: 20px;
    background: none;
    border: none;
    outline: none;
    z-index: 2;
}

.header-nav > .header-wrap > .info-menu-btn > svg{
    font-weight: 400;
    font-size: 22px;
    color: rgb(71, 68, 68);
    opacity: .8;
}
/* info menu btn end*/


/* sidebar toggle button */
.header-nav > .header-wrap > #toggleSidebarBtn{
    width: auto;
    height: auto;
    position: absolute;
    float: left;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    align-items: center;
    left: 20px;
    background: none;
    border: none;
    outline: none;
    z-index: 2;
}

.header-nav > .header-wrap > #toggleSidebarBtn > svg{
    font-weight: 400;
    font-size: 22px;
    color: rgb(71, 68, 68);
    opacity: .8;
}


/* navigation area */
.header-nav > .header-wrap > .nav-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

/* Profile area */
.header-nav > .header-wrap > .nav-wrap > .profile-wrap{
    width: auto;
    height: 100%;
    position: relative;
    float: right;
    border: none;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-family: 'Roboto', sans-serif;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .info{
    width: auto;
    height: auto;
    position: relative;
    float: left;
    padding-left: 12px;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .info .user-name{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: block;
    color: #fff;
    opacity: 1;
    font-weight: 500!important;
    font-size: 15px;
    text-align: end;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .info .user-role{
    font-style: normal;
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: block;
    text-align: end;
    color: #fff;
    font-weight: 300!important;
    font-size: small;
    line-height: 1!important;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .avatar-image{
    width: 42px;
    height: 42px;
    position: relative;
    float: left;
    display: inline-block;
    margin-left: 15px;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .avatar-image > img{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    border-radius: 50%;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .avatar-image .status{
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    top: 70%;
    border-radius: 50%;

}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .avatar-image .status.active{
    background: #28c76f;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .avatar-image .status.passive{
    background: #ea5455;
}

/* navigation elements */

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .navigation-element{
    width: auto;
    height: 100%;
    position: relative;
    float: right;
    display: flex;
    align-items: center;
    background: none;
    outline: none;
    border: none;
    margin: 0 8px;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap  .navigation-element.notification > svg{
    font-size: 24px;
    font-weight: 400;
    color: rgb(71, 68, 68);
    opacity: .8;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap  .navigation-element.notification > .cstm-badge{
    width: 18px;
    height: 18px;
    position: absolute;
    top: -9px;
    background-color: #ea5455;
    right: -7px;
    font-size: 12px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap  .navigation-element.calendar > svg{
    font-size: 24px;
    font-weight: 400;
    color: rgb(71, 68, 68);
    opacity: .8;
}

/* dropdown menu */
.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu{
    width: 10rem;
    position: absolute;
    content: '';
    right: 0;
    top: calc(100% + 17px);
    list-style: none;
    background-color: #fff;
    padding: 0;
    z-index: 999;
    transition: 'max-height 0.6s ease-out';
    box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
}


/* dropdown overflow scrollbar area */
.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu::-webkit-scrollbar {
    display: none;
}

/* Track */
.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu::-webkit-scrollbar-track {
    display: none;
}

/* Handle */
.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu::-webkit-scrollbar-thumb {
    display: none;
}

/* Handle on hover */
.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu::-webkit-scrollbar-thumb:hover {
    display: none;
}
/* dropdown overflow scrollbar end */

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu li{
    width: 100%;
    display: block!important;
    height: auto;
    position: relative;
    float: left;
    margin: 3px 0;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu li a{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    color: #6e6b7b;
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
    line-height: 2.1;
    display: flex;
    align-items: center;
    opacity: 0.8;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: color .3s ease-in, background-color .3s ease-in;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu li:hover a{
    color: #1172b6;
    background-color: #e5eaed;
    transition: color .3s ease-in, background-color .3s ease-in;
}

.header-nav > .header-wrap > .nav-wrap > .profile-wrap .cstm-dropdown-menu li svg{
    margin-right: 8px;
    display: inline-block!;
}

/* dropdown menu end*/

/* search area */
.header-nav > .header-wrap > .nav-wrap > .search-area{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 60px;
}


.header-nav > .header-wrap > .nav-wrap > .search-area > .input{
    width: 100%;
    max-width: 600px;
    height: auto;
    position: relative;
    float: left;
}

.header-nav > .header-wrap > .nav-wrap > .search-area > .input > input{
    width: 100%!important;
    height: auto;
    position: relative;
    float: left;
    border-radius: 6px;
    padding: 10px 20px 10px 15px;
    background-color: #f3f3f3;
    font-size: 14px!important;
    color: #333!important;
    transition: background-color .3s ease-in;
}

.header-nav > .header-wrap > .nav-wrap > .search-area > .input > input:focus{
    background-color: #fff;
    transition: background-color .3s ease-in;
}

.header-nav > .header-wrap > .nav-wrap > .search-area > .input > .search-btn{
    width: 20px;
    height: 20px;
    position: absolute;
    content: '';
    right: 17px;
    border: none;
    background: none;
    top: 8px;
    color: #888888;
    transition: color .3s ease-in;
}

.header-nav > .header-wrap > .nav-wrap > .search-area > .input > .search-btn:hover{
    color: #1172b6;
    transition: color .3s ease-in;
}

.header-nav > .header-wrap > .nav-wrap > .search-area > .input > input:focus ~ .search-btn{
    color: #1172b6;
    transition: color .3s ease-in;
}

/* search area end*/

/* header media query*/
@media screen and (max-width: 992px) {
    .header-nav > .header-wrap > #toggleSidebarBtn {
        display: flex;
    }
    .header-nav > .header-wrap > .info-menu-btn{
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .header-nav > .header-wrap > .nav-wrap > .search-area {
        padding-left: 60px;
    }
}

@media screen and (max-width: 650px) {
    .header-nav > .header-wrap > .nav-wrap{
        display: block;
    }

    .header-nav > .header-wrap > .nav-wrap > .search-area {
        padding: 12px 0 0 0;
    }

    .header-nav > .header-wrap > #toggleSidebarBtn{
        top: 26%;
    }
}

@media screen and (max-width: 375px) {
    .header-nav > .header-wrap > .nav-wrap > .profile-wrap .info{
        display: none;
    }
}

/* header media query end*/




