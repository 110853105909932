
/* material data-grid cell focus remove border*/
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer{
    border-top: 2px solid rgb(193, 185, 185);
}

.MuiDataGrid-root .MuiDataGrid-columnHeaders{
    background-color: #e6e6e6;
    /* border-bottom: 2px solid rgb(193, 185, 185); */
}

.MuiDataGrid-root .MuiDataGrid-toolbarContainer{
    margin-bottom: 15px;
}

.MuiDataGrid-root .MuiDataGrid-toolbarContainer button{
    margin-right: 5px;
    display: flex;
    align-items: center;
   line-height: normal;
   padding: 3px 5px;
}

.MuiDataGrid-root .MuiDataGrid-toolbarContainer button span{
    display: flex;
    align-items: center;
    line-height: normal;
}

.MuiDataGrid-root .MuiDataGrid-toolbarContainer > button:nth-child(-n + 4){
    border: 2px solid #1172b6;
    width: 120px;
    height: 34px;
    color: #1172b6;
    margin: 5px 6px;
}


.MuiDataGrid-root .MuiDataGrid-toolbarContainer > button:nth-child(-n + 4):hover{
    background: #36bef0;
    color: #fff;
    border-color: #36bef0;
}


@media only screen and (max-width: 1159.9px) and (min-width: 992.9px) {
    .MuiDataGrid-root .MuiDataGrid-toolbarContainer .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter{
        width: 100%;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 860px) {
    .MuiDataGrid-root .MuiDataGrid-toolbarContainer .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter{
        width: 100%;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 354px) {
    .MuiDataGrid-root .MuiDataGrid-toolbarContainer > button:nth-child(-n + 4){
        width: 100%;
    }
}

/* mobile view visible selected count */
.MuiDataGrid-selectedRowCount.css-de9k3v-MuiDataGrid-selectedRowCount{
    visibility: visible;
}

/* mui data grid filter form  */

.MuiDataGrid-filterForm.css-1t5wrdm-MuiDataGrid-filterForm{
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.MuiDataGrid-filterForm.css-1t5wrdm-MuiDataGrid-filterForm > div:nth-child(-n+5){
    width: 100%;
    margin-top: 10px;
}

.MuiDataGrid-filterForm.css-1t5wrdm-MuiDataGrid-filterForm > .MuiDataGrid-filterFormDeleteIcon{
    background: none;
    background: transparent;
}

.MuiDataGrid-filterForm.css-1t5wrdm-MuiDataGrid-filterForm > .MuiDataGrid-filterFormDeleteIcon > button{
    display: flex;
    background: transparent;
    justify-content: flex-end;
}