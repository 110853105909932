@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

html,
body {
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    font-family: 'Roboto', sans-serif;
}

/* App page area */
.app-p-container {
    width: 100%;
    height: 100vh;
    position: relative;
    float: left;
    overflow: hidden;
    background: #f3f3f3;
    padding-left: 260px;
    transition: padding-left 0.4s ease;
}

/* app p sidebar area */
.app-p-sidebar-wrapper {
    width: 260px;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    background: #1976d217;
    background: #1976d21f;
    background: #fff;
    z-index: 999;
    overflow: auto;
    transition: left 0.4s ease;
}

/* app content area */
.app-p-content-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    float: left;
    overflow: auto;
    padding: 20px 30px;
}

@media only screen and (max-width: 992px) {
    .app-p-sidebar-wrapper {
        left: -260px;
        transition: left 0.4s ease;
    }

    .app-p-container {
        padding-left: 0;
        transition: padding-left 0.4s ease;
    }

    .app-p-content-wrapper {
        padding: 20px 10px;
    }
}

/* validation error message */
.validation-error{
    font-size: 14px;
    color: #e53e3e;
    font-family: 'Roboto', sans-serif;
}

/* login page login-button effect */
.button-click-animate:active {
    box-shadow: 0 15px rgb(10, 9, 9);
    transform: translateY(4px);
}
/* form custom elements */
input.cstm-inpt,
textarea.cstm-txta {
    outline: none !important;
    box-shadow: none !important;
    border: 1.6px solid #ced4da !important;
    font-size: 18px !important;
    color: #565656 !important;
    font-family: 'Roboto', sans-serif !important;
}

input.cstm-inpt:focus,
textarea.cstm-txta:focus {
    border: 1.7px solid rgba(17, 114, 182, 0.749) !important;
}

input.cstm-inpt[type="checkbox"] {
    width: 20px;
    height: 20px;
    position: relative;
    float: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none !important;
    outline: none !important;
}

input.cstm-inpt[type="checkbox"]::before {
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    content: '';
    position: absolute;
    float: left;
    border: 2px solid #d5d5d5;
    border-radius: 5px;
    outline: none !important;
}

input.cstm-inpt[type="checkbox"]:checked {
    background-color: #1172b6;
}

.card-form-panel-title{
    color: #1172b6;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
    font-family: 'Roboto', sans-serif;
    text-decoration: underline;
}

.cstm-form-label-style{
    color: #5E5873;
    font-size: 0.857rem;
    font-family: 'Roboto', sans-serif;
    
}

.cstm-validation-error-msg{
    font-size: 13px;
    color: #e53e3e;
    font-family: 'Roboto', sans-serif;
}

.cstm-border-top{
    border-top: 4px solid #1172b6;
}

/* input error messages */
.cstm-input-error-validation-msg{
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}


/* custom phone input mask */
.custom-mui-input-cls{
    border: 1px solid rgba(0, 0, 0, 0.27);
    padding: 3.5px 14px;
    padding-bottom: 0;
    padding-top: 5px;
    border-radius: 4px;
}

.custom-mui-input-cls::before{
    border: none!important;
    outline: none!important;
    transition:none!important;
}

.custom-mui-input-cls::after{
    border: none!important;
    outline: none!important;
    transition:none!important;
}

.custom-mui-input-cls::before:focus{
    border: none!important;
    outline: none!important;
    transition:none!important;
}

.custom-mui-input-cls::after:focus{
    border: none!important;
    outline: none!important;
    transition:none!important;
}

.custom-mui-input-cls + .cstm-label{
    position: absolute;
    color: rgb(25, 118, 210);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    -webkit-transform: translate(14px, -9px) scale(0.75);
    -moz-transform: translate(14px, -9px) scale(0.75);
    -ms-transform: translate(14px, -9px) scale(0.75);
    transform: translate(14px, -9px) scale(0.75);
    -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    padding: 0 6px;
    left: -3px;
    top: -1px;
}
/* custom phone input mask end */
