.laboratory-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
}

.lab-job-blink_me {
    animation: lab-job-blinker 1s linear infinite;
}
  
@keyframes lab-job-blinker {
    90% {
        opacity: 0.1;
    }
}