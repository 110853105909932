.patients-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 20px 15px 30px 15px;
}


/* create patinet */
.patient-image-preview-wrap{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.patient-image-preview-wrap > img{
    width: 150px;
    height: 120px;
    position: relative;
    border-radius: 10px;
    border: 2px solid #37bef0;;
}

.patient-image-upload-btn{
    background: #37bef0;
    color: #fff;
    padding: 5px 15px;
    margin-right: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.patient-image-remove-btn{
    background: #cb3e3e;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}


/* custom radio button */

.patient-cstm-radio-btns{
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 40px;
}

.patient-cstm-radio-btns .patient-cstm-gender-radio{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.patient-cstm-radio-btns .patient-cstm-gender-radio input{
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    cursor: pointer;
}

.patient-cstm-radio-btns .patient-cstm-gender-radio input + label{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border: 2px solid #e6e6e6;
}

.patient-cstm-radio-btns .patient-cstm-gender-radio  input:checked + label{
    border-color: #1172b6;
    color: #1172b6;
    background-color: #93bddb33;
}
