.dashboard-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
}

.dashboard-container > .header-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: #1172b6;
    padding-bottom: 10px;
    border-bottom: 1px dashed #d2d2d2;
    margin: 10px 0;
    margin-bottom: 20px;
}

.dashboard-container .card-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    padding: 5px 10px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background-clip: border-box;
    border: 1px solid rgba(34, 41, 47, 0.125);
    border-radius: 0.428rem;
    background: #f0f3f9;
    
}

.dashboard-container .card-wrap > .card-header{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    padding: 15px;
}

.dashboard-container .card-wrap > .card-header > h5{
    color: #7e90ba;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
}

.dashboard-container .card-wrap .card-body{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: flex;
    align-items: center;
}

.dashboard-container .card-wrap .card-body > .card-icon{
    width: 70px;
    height: 70px;
    position: relative;
    float: left;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6eefd;
    color: #1172b6;
    font-weight: 400;
    font-size: 32px;
    border-radius: 50%;
    margin-right: 10px;
}


.table-striped-dashbord-patients > tbody > tr:nth-child(2n) > td, 
.table-striped-dashbord-patients > tbody > tr:nth-child(2n) > th {
    background-color: #fafafa;
 }

 .dashboard-patients-section{
    margin:10px ;
    padding: 20px;
    border: 2px solid #eee;
    background-color: #ffffff;
    margin-top: 40px;
    border-radius: 10px;
 }

 
 .dashboard-patients-section-tableRow{
    background-color: #e6ebf5;
 }

  
 .dashboard-patients-section-allPatients-button{
   float: right;
   margin-right: 2% !important;
   margin-bottom: 15px !important;
   background-color: #1172b6;
 }
 
 .dashboard-patients-section-table-status{
    width:  50px;
    text-align: center;
  }
