.patient-detail-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 0 30px 0;
}

.invoice-detail-cstm-inpt span{
    background-color: #e9ecef;
    width: 100%;
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 38px;
}

.invoice-detail-cstm-inpt .txt-area{
    height: 80px;
}

#invoice-pre-loading-content{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: rgba(195, 195, 195, 0.5);
}

#invoice-pre-loading-content > .loading{
    width: auto;
    height: auto;
    position: relative;
    color: #fff;
}