.sidebar-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    padding: 100px 10px 0 10px;
}

.sidebar-container > .logo-wrap{
    width: 100%;
    height: 100px;
    position: absolute;
    float: left;
    top: 0;
    left: 0;
    right: 0;
    padding-left: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-container > .logo-wrap > .logo{
    width: auto;
    height: auto;
    position: relative;
    float: left;
}

.sidebar-container > .logo-wrap > .close-nav{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    border-radius: 4px;
    background-color: #49c4f1;
    display: none;
}

@media only screen and (max-width: 992px) {
    .sidebar-container > .logo-wrap > .close-nav{
        display: inline-block;
    }
}

.sidebar-container > .sidebar-elements-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    margin-top: 20px;
    padding: 0!important;
}

.sidebar-container > .sidebar-elements-wrap li{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    list-style: none;
}

.sidebar-container > .sidebar-elements-wrap li > a{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    text-decoration: none;
    color: #625F6E;
    font-size: 18px;  
    padding: 10px 0 10px 55px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    transition: background-color .3s ease, padding-left .3s ease;
}

.sidebar-container > .sidebar-elements-wrap li > a > .icon{
    width: 30px;
    height: auto;
    position: absolute;
    content: '';
    left: 13px;
    text-align: center;
    font-size: 20px;
    font-weight: 300;   
    opacity: 0.8; 
    transition: left .2s ease;
}

.sidebar-container > .sidebar-elements-wrap li > a.active{
    background-color: #1172b6;
    border-radius: 5px;
    color: #fff;
}

.sidebar-container > .sidebar-elements-wrap li > a:not(.active):hover{
    background-color: whitesmoke;
    transition: background-color .3s ease, padding-left .2s ease;
    padding-left: 65px;
}

.sidebar-container > .sidebar-elements-wrap li > a:not(.active):hover .icon{
    left: 23px;
    transition: left .2s ease;
}



