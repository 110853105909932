
.loading-page-wrapper{
    width: 100%;
    height: 100vh;
    position: relative;
    float: left;
    overflow: hidden;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}