.reports-page-container{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
}


.reports-page-container > .header-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: #1172b6;
    padding-bottom: 10px;
    border-bottom: 1px dashed #d2d2d2;
    margin: 10px 0;
    margin-bottom: 20px;
}

.reports-page-container .card-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    padding: 5px 10px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background-clip: border-box;
    border: 1px solid rgba(34, 41, 47, 0.125);
    border-radius: 0.428rem;
    /* background: #f0f3f9; */
    background: rgb(70,166,227);
background: linear-gradient(90deg, rgba(70,166,227,0.5) 0%, rgba(94,149,198,0.5) 0%, rgba(5,169,234,0.5) 0%, rgba(50,125,177,0.7) 100%);
    
}

.reports-page-container .card-wrap > .card-header{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    padding: 15px 0;
}

.reports-page-container .card-wrap > .card-header > h5{
    /* color: #7e90ba; */
    color: #fff;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
}

.reports-page-container .card-wrap > .card-header > span{
    /* color: #7e90ba; */
    color: #fff;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin-top: -6px;
}

.reports-page-container .card-wrap .card-body{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: flex;
    align-items: center;
}

.reports-page-container .card-wrap .card-body > .card-icon{
    width: 40px;
    height: 40px;
    position: relative;
    float: left;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #609bc6;
    font-weight: 400;
    font-size: 28px;
    border-radius: 50%;
    margin-right: 10px;
}

.reports-page-container .card-wrap > .card-footer{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    margin-top: 10px;
    padding: 15px 0;
    border-top: 1px dashed #d2d2d2;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}