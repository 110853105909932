.login-page-wrapper{
    width: 100%;
    height: auto;
    position: relative;
    float:left;
}

/* login page content */
.login-page-wrapper > .content-wrapper{
    width: 100%;
    height: 100vh;
    position: relative;
    float: left;   
    overflow: hidden;
}

.login-page-wrapper > .content-wrapper .row{
    height: 100%;
}


/* illustarion */
.login-page-wrapper > .content-wrapper .img-container{
    width: 100%;
    height: 100%;
    position: relative;
    float: left;  
    background-color: #05a9ea;
    display: flex;
    justify-content: center;
    align-items:center;
    overflow: hidden;
} 


/* form container */
.login-page-wrapper > .content-wrapper .form-container{
    width: 100%;
    height: 100vh;
    position: relative;
    float: left;  
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
} 

.login-page-wrapper > .content-wrapper .form-container > .form-wrap{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    padding: 0 100px 0 60px;
}



.login-page-wrapper > .content-wrapper .form-container > .form-wrap > .logo{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap > .logo > img{
    width: auto;
    height: auto;
    max-height: 55px;
    max-width: 180px;
    position: relative;
    float: left;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form label{
    color: #929292;
    font-size: 16px;
    margin: 3px 0;
    font-family: 'Roboto', sans-serif;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form input::placeholder{
    color: #a8a8a8;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form .form-group{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    margin: 4px 0;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form .password-area input{
    width: 100%;
    height: 42px;
    position: relative;
    float: left;
    display: flex;
    align-items: center;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form .password-area .toggle-password{
    content:'';
    position: absolute;
    width: 30px;
    right: 10px;
    top: 53%;
    border: none;
    outline: none;
    background: none;
    text-align: center;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form .password-area .validation-error ~ .toggle-password{
    top: 39%;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form .rem-me-area{
    margin-top: 15px;
}


.login-page-wrapper > .content-wrapper .form-container > .form-wrap form .rem-me{
    font-size: 13px;
    margin-left: 10px;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form .submit-btn{
    width: 100%;
    height: auto;
    position: relative;
    float: left;
    outline: none;
    border: none;
    padding: 7px 0;
    margin-top: 15px;
    border-radius: 5px;
    color: #fff;
    transition: .3s ease;
    background: #1172b6;
    font-family: 'Roboto', sans-serif;
}

.login-page-wrapper > .content-wrapper .form-container > .form-wrap form .submit-btn:hover{
    box-shadow: 0px 0px 13px 1px rgba(33,119,180,0.5);
    -webkit-box-shadow: 0px 0px 13px 1px rgba(33,119,180,0.5);
    -moz-box-shadow: 0px 0px 13px 1px rgba(33,119,180,0.5);
    transition: .3s ease;
}


@media only screen and (max-width: 1200px) {
    .login-page-wrapper > .content-wrapper .img-container > img{
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 992px) {
    .login-page-wrapper > .content-wrapper .img-container{
        display: none;
    }
}

@media only screen and (max-width: 992px) {
    .login-page-wrapper > .content-wrapper .form-container > .form-wrap{
        width: 400px;
        height: auto;
        padding: 0;
    }
}

@media only screen and (max-width: 500px) {
    .login-page-wrapper > .content-wrapper .form-container > .form-wrap{
        width: 100%;
        padding: 0 20px;
    }
}

